import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

import Layout from "../../components/Layout";

import Loader from "../../components/loader/Loader";
import { blockUser, getUser } from "../../redux/thunk/userThunk";
import { resetUser } from "../../redux/slice/userSlice";
import { Link, useParams } from "react-router-dom";

const ListUser = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const { userId } = useParams();
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [userModal, setUserModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [stage, setStage] = useState(1);
  const { user, blockedUserData } = useSelector((state) => state.userState);
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const filterUsers = (user, userId) => {
    if (!Array.isArray(user)) {
      return [];
    }
    return user?.filter((item) => (userId ? item.user_id === userId : true));
  };
  useEffect(() => {
    setFilteredUserData(filterUsers(user, userId));
  }, [user, userId]);

  useEffect(() => {
    if (blockedUserData.result) {
      setId("");
      setBlockModal(false);
      dispatch(resetUser());
    }
    dispatch(getUser());
  }, [blockedUserData.result, dispatch]);

  useEffect(() => {
    userModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [userModal]);
  useEffect(() => {
    blockModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [blockModal]);

  const openUserModal = (value) => {
    setSelectedUser(value);
    setId(value.user_id);
    setUserModal(true);
  };

  const [switchClicked, setSwitchClicked] = useState(false);
  const handleSwitchClick = (value) => {
    setId(value.user_id);
    setBlockModal(true);
  };

  useEffect(() => {
    const switchElement = document.querySelector(".switch");
    if (switchElement) {
      switchElement.classList.toggle("switchslide", switchClicked);
    }
  }, [switchClicked]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name",
        accessor: (row) => row.display_name,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Phone Number",
        accessor: (row) => row.phone_number,
      },
      {
        Header: "Gender",
        accessor: (row) => row.gender,
      },

      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Block",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              className={`${row.flag == "0" ? "toggle" : "toggleactive"}`}
              onClick={() => handleSwitchClick(row)}
            >
              <div
                className={` ${row.flag == "0" ? "switch" : "switchslide"}`}
              ></div>
            </div>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <i
              className="fa-regular fa-eye actionlinks-view"
              onClick={() => openUserModal(row)}
            ></i>
            <Link to={`/rides/${row?.user_id}`} className="actionlinks-ride">
              View Rides
            </Link>
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredUserData,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleBlockUser = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const data = {
        id: id,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(blockUser(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader isActive={true} />}
      <Layout>
        <div className="listpage">
          <div className="listpage-top">
            <h1>User List</h1>
            <div className="listpage-top--textlink">
              <h2>
                <span>
                  <i className="fa-regular fa-users"></i>
                </span>
                &nbsp;/ User Management
              </h2>
              <div className="listpage-top--textlink_addsetting"></div>
            </div>
          </div>

          <div className="listpage-searchentry">
            <div className="listpage-searchentry--search">
              <input
                type="search"
                placeholder="Search here..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
              />
              <i className="fa-regular fa-magnifying-glass"></i>
            </div>

            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="listpage-searchentry--pageselect"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={filteredUserData.length}>All</option>
            </select>
          </div>

          <div className="listpage-tablesection">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {pageSize !== filteredUserData.length && (
              <div className="listpage-tablesection--pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>

        {userModal && selectedUser && (
          <div className="overlay">
            <div className="modal" style={{ width: "700px" }}>
              <div className="modal-header">
                <h2>User Details</h2>
                <i
                  onClick={() => setUserModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="drivergrid">
                  <div
                    className="drivergrid-drivermenu"
                    style={{ height: "40vh" }}
                  >
                    <button
                      onClick={() => setStage(1)}
                      className={
                        stage === 1
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Basic Information
                    </button>

                    <button
                      onClick={() => setStage(2)}
                      className={
                        stage === 2
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Ride Information
                    </button>
                  </div>
                  <div className="drivergrid-drivercontent">
                    {stage === 1 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Basic Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_info">
                          <p>
                            <strong>Name: </strong>
                            {selectedUser.display_name}
                          </p>
                          <p>
                            <strong>Gender: </strong>
                            {selectedUser.gender}
                          </p>
                          <p>
                            <strong>Phone Number: </strong>
                            {selectedUser.phone_number}
                          </p>

                          {selectedUser.date_of_birth && (
                            <p>
                              <strong>D.O.B: </strong>
                              {selectedUser.date_of_birth}
                            </p>
                          )}
                          {selectedUser.email && (
                            <p>
                              <strong>Email: </strong>
                              {selectedUser.email}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {stage === 2 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Ride Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_ride">
                          <div className="drivergrid-drivercontent--stagediv_ride__rideitem">
                            <h2>Total Rides</h2>
                            <p>{selectedUser.total_rides}</p>
                          </div>
                          <div className="drivergrid-drivercontent--stagediv_ride__rideitem">
                            <h2 style={{ color: "#fb3535" }}>
                              Cancelled Rides
                            </h2>
                            <p>{selectedUser.cancelled_rides}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {blockModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Block User</h2>
                <i
                  onClick={() => setBlockModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>Are you sure you want to change block status?</p>
                  <div className="approvediv-btns">
                    <button
                      className="primary-button"
                      onClick={handleBlockUser}
                    >
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setBlockModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ListUser;
