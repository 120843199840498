import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { doPost } from "../../axios/instance";

export const loginUser = createAsyncThunk("loginUser", async (data) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // };
    // const response = await axios.post(
    //   `${baseUrl}/system/users/login`,
    //   data,
    //   config
    // );
    const response = await doPost(`system/admins/login`, data);
    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userDetails", JSON.stringify(response.data.data));
      toast.success("Logged in Successfully");
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});
