import { createSlice } from "@reduxjs/toolkit";
import {
  addNotification,
  deleteNotification,
  getNotification,
} from "../thunk/notificationThunk";

const initialState = {
  notification: [],
  addedNotificationData: [],
  deletedNotificationData: [],
  loading: false,
  success: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notification = action.payload;
        state.error = null;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.addedNotificationData = action.payload;
        state.error = null;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.deletedNotificationData = action.payload;
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
