import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet, doPost } from "../../axios/instance";
import { toast } from "react-toastify";

export const getConfiguration = createAsyncThunk(
  "getConfiguration",
  async () => {
    try {
      const response = await doGet(`system/configuration`);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
      throw error;
    }
  }
);

export const addConfiguration = createAsyncThunk(
  "addConfiguration",
  async (data) => {
    try {
      const response = await doPost(`system/configuration`, data);

      if (response.status === 200) {
        toast.success("Configuration Changed Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);
