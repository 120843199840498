import React, { useEffect, useState } from "react";
import { logo } from "../constant/ImageList";

import Loader from "../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/thunk/loginThunk";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState(true);
  const { success } = useSelector((state) => state.loginState);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!username) {
      toast.error("Username is Required");
      return false;
    }
    if (!password) {
      toast.error("Password is Required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        username: username,
        password: password,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(loginUser(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    }
  }, [success, navigate]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <>
      {loading && <Loader isActive={true} />}
      <div className="loginform">
        <div className="loginform-loginformtop">
          <div className="loginform-loginformtop--loginformtoptext">
            <h1>Welcome Back</h1>
            <h2>
              Sign in to Continue to <strong>AUTOME</strong> Dashboard
            </h2>
          </div>
          <div className="loginform-loginformtop--loginformtopimage">
            <img src={logo} alt="" height="100%" width="100%" />
          </div>
        </div>

        <form className="loginform-loginformform" onSubmit={handleLoginSubmit}>
          <div>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              placeholder="Enter your username ..."
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <div className="passwordbutton">
              <input
                type={passwordType ? "password" : "text"}
                id="password"
                name="password"
                placeholder="Enter your password ..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPasswordType(!passwordType);
                }}
                className="passwordbutton-button"
              >
                {passwordType ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </button>
            </div>
          </div>

          <div className="submitbtn">
            <input type="submit" value="Login" className="primary-button" />
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
