import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

import Layout from "../../components/Layout";

import Loader from "../../components/loader/Loader";

import {
  approveDriver,
  blockDriver,
  getDriver,
  getDriverCommission,
  sendNotification,
  settleCommission,
} from "../../redux/thunk/driverThunk";
import { key, url } from "../../utils/env";
import { toast } from "react-toastify";
import { resetDriver } from "../../redux/slice/driverSlice";
import { logo, nodata } from "../../constant/ImageList";
import { Link, useParams } from "react-router-dom";

const ListDriver = () => {
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [driverModal, setDriverModal] = useState(false);
  const [filteredDriverData, setFilteredDriverData] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [approveFormModal, setApproveFormModal] = useState(false);
  const [commissionModal, setCommissionModal] = useState(false);
  const [settleModal, setSettleModal] = useState(false);
  const [commission, setCommission] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [stage, setStage] = useState(1);
  const {
    driver,
    statusDriverData,
    blockedDriverData,
    driverCommission,
    settleCommissionData,
    notificationData,
  } = useSelector((state) => state.driverState);

  useEffect(() => {
    dispatch(getDriver());
  }, [dispatch]);

  const filterDrivers = (driver, driverId) => {
    if (!Array.isArray(driver)) {
      return [];
    }
    return driver?.filter((item) =>
      driverId ? item.driver_id === driverId : true
    );
  };
  useEffect(() => {
    setFilteredDriverData(filterDrivers(driver, driverId));
  }, [driver, driverId]);
  useEffect(() => {
    if (Object.keys(statusDriverData).length > 0) {
      setStatus("");
      setMessage("");
      setApproveFormModal(false);
      setDriverModal(false);
      dispatch(resetDriver());
    }
    dispatch(getDriver());
  }, [statusDriverData, dispatch]);

  useEffect(() => {
    if (settleCommissionData.result) {
      setId("");
      setSettleModal(false);
      setCommissionModal(false);

      setDriverModal(false);
      dispatch(resetDriver());
    }
    dispatch(getDriver());
  }, [settleCommissionData.result, dispatch]);

  useEffect(() => {
    if (blockedDriverData.result) {
      setId("");
      setBlockModal(false);
      dispatch(resetDriver());
    }
    dispatch(getDriver());
  }, [blockedDriverData.result, dispatch]);

  useEffect(() => {
    if (notificationData.result) {
      setId("");
      setNotificationTitle("");
      setNotificationMessage("");
      setDriverModal(false);
      dispatch(resetDriver());
    }
    dispatch(getDriver());
  }, [notificationData.result, dispatch]);

  useEffect(() => {
    driverModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [driverModal]);
  useEffect(() => {
    blockModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [blockModal]);
  useEffect(() => {
    approveFormModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [approveFormModal]);
  useEffect(() => {
    commissionModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [commissionModal]);

  const openDriverModal = (value) => {
    setSelectedDriver(value);
    setId(value.driver_id);
    setDriverModal(true);
  };
  const handleProceedButton = () => {
    setApproveModal(false);
    setApproveFormModal(true);
  };
  const getStatusBackgroundColor = (status) => {
    if (status === "Pending") {
      return "#e6602b";
    } else if (status === "Rejected") {
      return "#fb3535";
    } else if (status === "Approved") {
      return "#044b37";
    }
  };
  const [switchClicked, setSwitchClicked] = useState(false);
  const handleSwitchClick = (value) => {
    setId(value.driver_id);
    setBlockModal(true);
  };

  useEffect(() => {
    const switchElement = document.querySelector(".switch");
    if (switchElement) {
      switchElement.classList.toggle("switchslide", switchClicked);
    }
  }, [switchClicked]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name",
        accessor: (row) => row.display_name,
        Cell: ({ value }) => (
          <div style={{ textTransform: "capitalize" }}>{value}</div>
        ),
      },
      {
        Header: "Phone Number",
        accessor: (row) => row.phone_number,
      },
      {
        Header: "License Number",
        accessor: (row) => (row.license_number ? row.license_number : "N/A"),
      },
      {
        Header: "Vehicle Number",
        accessor: (row) => (row.vehicle_number ? row.vehicle_number : "N/A"),
      },
      {
        Header: "Status",
        accessor: (row) => (
          <span
            style={{
              backgroundColor: getStatusBackgroundColor(row.status),
            }}
            className="status"
          >
            {row.status}
          </span>
        ),
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Block",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              className={`${row.flag == "0" ? "toggle" : "toggleactive"}`}
              onClick={() => handleSwitchClick(row)}
            >
              <div
                className={` ${row.flag == "0" ? "switch" : "switchslide"}`}
              ></div>
            </div>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <i
              className="fa-regular fa-eye actionlinks-view"
              onClick={() => {
                openDriverModal(row);
                dispatch(getDriverCommission(row.driver_id));
              }}
            ></i>
            <Link to={`/rides/${row?.driver_id}`} className="actionlinks-ride">
              View Rides
            </Link>
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredDriverData,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleApproveDriver = async (event) => {
    event.preventDefault();

    if (!status) {
      toast.error("Please select status");
      return false;
    }
    if (status === "Rejected" && !message) {
      toast.error("Message is Required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        driver_id: id,
        status: status,
        message: message,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(approveDriver(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleCommission = async (event) => {
    event.preventDefault();

    if (!commission) {
      toast.error("Commission amount is required");
      return false;
    }
    if (commission > selectedDriver.remaining_commission) {
      toast.error("Commission amount is less than that");
      return false;
    }
    setSettleModal(true);
  };

  const handleSettle = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const data = {
        driver_id: id,
        amount: commission,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(settleCommission(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSendNotification = async (event) => {
    event.preventDefault();

    if (!notificationTitle) {
      toast.error("Notification Title is required");
      return false;
    }
    if (!notificationMessage) {
      toast.error("Notification Message is required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        id: id,
        title: notificationTitle,
        message: notificationMessage,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(sendNotification(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleBlockDriver = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const data = {
        id: id,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(blockDriver(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const renderStarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const decimal = rating - fullStars;
    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <i key={i} className="fas fa-star" style={{ color: "#003060" }}></i>
      );
    }

    if (decimal >= 0.05 && decimal < 0.75) {
      starElements.push(
        <i
          key="half"
          className="fas fa-star-half-alt"
          style={{ color: "#003060" }}
        ></i>
      );
    } else if (decimal >= 0.75) {
      starElements.push(
        <i key="full" className="fas fa-star" style={{ color: "#003060" }}></i>
      );
    }

    while (starElements.length < 5) {
      starElements.push(
        <i key={starElements.length} className="far fa-star"></i>
      );
    }

    return starElements;
  };

  return (
    <>
      {loading && <Loader isActive={true} />}
      <Layout>
        <div className="listpage">
          <div className="listpage-top">
            <h1>Driver List</h1>
            <div className="listpage-top--textlink">
              <h2>
                <span>
                  <i className="fa-regular fa-user-police"></i>
                </span>
                &nbsp;/ Driver Management
              </h2>
              <div className="listpage-top--textlink_addsetting"></div>
            </div>
          </div>

          <div className="listpage-searchentry">
            <div className="listpage-searchentry--search">
              <input
                type="search"
                placeholder="Search here..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
              />
              <i className="fa-regular fa-magnifying-glass"></i>
            </div>

            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="listpage-searchentry--pageselect"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={filteredDriverData.length}>All</option>
            </select>
          </div>

          <div className="listpage-tablesection">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {pageSize !== filteredDriverData.length && (
              <div className="listpage-tablesection--pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>

        {driverModal && selectedDriver && (
          <div className="overlay">
            <div className="modal" style={{ width: "700px" }}>
              <div className="modal-header">
                <h2>Driver Details</h2>
                <i
                  onClick={() => setDriverModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="drivergrid">
                  <div className="drivergrid-drivermenu">
                    <button
                      onClick={() => setStage(1)}
                      className={
                        stage === 1
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Basic Information
                    </button>
                    <button
                      onClick={() => setStage(2)}
                      className={
                        stage === 2
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Driving License Information
                    </button>
                    <button
                      onClick={() => setStage(3)}
                      className={
                        stage === 3
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Blue Book Information
                    </button>
                    <button
                      onClick={() => setStage(4)}
                      className={
                        stage === 4
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Vehicle Information
                    </button>
                    <button
                      onClick={() => setStage(5)}
                      className={
                        stage === 5
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Ride Information
                    </button>
                    <button
                      onClick={() => setStage(6)}
                      className={
                        stage === 6
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Commission
                    </button>
                    <button
                      onClick={() => setStage(7)}
                      className={
                        stage === 7
                          ? "drivergrid-drivermenu--activebtn"
                          : "drivergrid-drivermenu--btn"
                      }
                    >
                      Send Notification
                    </button>
                  </div>
                  <div className="drivergrid-drivercontent">
                    {stage === 1 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Basic Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_info">
                          <p>
                            <strong>Name: </strong>
                            {selectedDriver.display_name}
                          </p>
                          <p>
                            <strong>Gender: </strong>
                            {selectedDriver.gender}
                          </p>
                          <p>
                            <strong>Phone Number: </strong>
                            {selectedDriver.phone_number}
                          </p>
                          <p>
                            <strong>Status: </strong>{" "}
                            <span
                              style={{
                                backgroundColor: getStatusBackgroundColor(
                                  selectedDriver.status
                                ),
                              }}
                              className="status"
                            >
                              {selectedDriver.status}
                            </span>
                          </p>
                          {selectedDriver.date_of_birth && (
                            <p>
                              <strong>D.O.B: </strong>
                              {selectedDriver.date_of_birth}
                            </p>
                          )}
                          {selectedDriver.email && (
                            <p>
                              <strong>Email: </strong>
                              {selectedDriver.email}
                            </p>
                          )}
                          <p>
                            <strong>Ratings: </strong>{" "}
                            {renderStarRating(selectedDriver.ratings)}{" "}
                            {selectedDriver.ratings &&
                              "(" +
                                parseFloat(selectedDriver.ratings).toFixed(2) +
                                ")"}
                          </p>
                          <p>
                            <strong>Passport Size Photo: </strong>
                          </p>
                          {selectedDriver.passport_size_photo ? (
                            <div>
                              <div
                                className="drivergrid-drivercontent--stagediv_info__image"
                                style={{ height: "150px", width: "150px" }}
                              >
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.passport_size_photo}`}
                                  alt=""
                                />
                              </div>
                              <div style={{ paddingTop: "0.5rem" }}>
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.passport_size_photo}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="drivergrid-drivercontent--stagediv_info__image"
                              style={{ height: "150px", width: "150px" }}
                            >
                              <img src={nodata} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {stage === 2 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Driving License Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_info">
                          <p>
                            <strong>License Number: </strong>
                            {selectedDriver.license_number
                              ? selectedDriver.license_number
                              : "N/A"}
                          </p>
                          <p>
                            <strong>License Image:</strong>
                          </p>
                          {selectedDriver.license ? (
                            <div>
                              <div className="drivergrid-drivercontent--stagediv_info__image">
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.license}`}
                                  alt=""
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "0.5rem",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.license}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="drivergrid-drivercontent--stagediv_info__image">
                              <img src={nodata} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {stage === 3 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Blue Book Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_info">
                          <p>
                            <strong>Blue Book Detail Page:</strong>
                          </p>
                          {selectedDriver.bill_book_detail_page ? (
                            <div>
                              <div className="drivergrid-drivercontent--stagediv_info__image">
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.bill_book_detail_page}`}
                                  alt=""
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "0.5rem",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.bill_book_detail_page}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="drivergrid-drivercontent--stagediv_info__image">
                              <img src={nodata} alt="" />
                            </div>
                          )}
                          <p>
                            <strong>Blue Book Owner Page:</strong>
                          </p>
                          {selectedDriver.bill_book_owner_page ? (
                            <div>
                              <div className="drivergrid-drivercontent--stagediv_info__image">
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.bill_book_owner_page}`}
                                  alt=""
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "0.5rem",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.bill_book_owner_page}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="drivergrid-drivercontent--stagediv_info__image">
                              <img src={nodata} alt="" />
                            </div>
                          )}
                          <p>
                            <strong>Blue Book Renew Page:</strong>
                          </p>
                          {selectedDriver.bill_book_renew_page ? (
                            <div>
                              <div className="drivergrid-drivercontent--stagediv_info__image">
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.bill_book_renew_page}`}
                                  alt=""
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "0.5rem",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.bill_book_renew_page}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="drivergrid-drivercontent--stagediv_info__image">
                              <img src={nodata} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {stage === 4 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Vehicle Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_info">
                          <p>
                            <strong>Vehicle Number: </strong>
                            {selectedDriver.vehicle_number
                              ? selectedDriver.vehicle_number
                              : "N/A"}
                          </p>
                          <p>
                            <strong>Vehicle Image:</strong>
                          </p>
                          {selectedDriver.vehicle_photo ? (
                            <div>
                              <div className="drivergrid-drivercontent--stagediv_info__image">
                                <img
                                  src={`${url}/assets/${key}/${selectedDriver.vehicle_photo}`}
                                  alt=""
                                />
                              </div>
                              <div
                                style={{
                                  paddingTop: "0.5rem",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`${url}/assets/${key}/${selectedDriver.vehicle_photo}`}
                                  target="_blank"
                                >
                                  View Full Image
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="drivergrid-drivercontent--stagediv_info__image">
                              <img src={nodata} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {stage === 5 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Ride Information</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_ride">
                          <div className="drivergrid-drivercontent--stagediv_ride__rideitem">
                            <h2>Total Rides</h2>
                            <p>{selectedDriver.total_rides}</p>
                          </div>
                          <div className="drivergrid-drivercontent--stagediv_ride__rideitem">
                            <h2 style={{ color: "#fb3535" }}>
                              Cancelled Rides
                            </h2>
                            <p>{selectedDriver.cancelled_rides}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {stage === 6 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Commission Payment</h2>
                        </div>
                        <div className="drivergrid-drivercontent--stagediv_payment">
                          <div className="drivergrid-drivercontent--stagediv_payment__card">
                            <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost">
                              <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--image">
                                <img src={logo} alt="" />
                              </div>
                              <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--cost">
                                <h2 className="left">Payment Left</h2>
                                <p>
                                  NPR{" "}
                                  {selectedDriver.remaining_commission.toFixed(
                                    2
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--btn">
                              <button
                                className="settle primary-button"
                                onClick={() => setCommissionModal(true)}
                              >
                                Settle
                              </button>
                            </div>
                          </div>
                          {Array.isArray(driverCommission) &&
                            driverCommission.length > 0 &&
                            driverCommission?.map((value) => (
                              <div className="drivergrid-drivercontent--stagediv_payment__card">
                                <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost">
                                  <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--image">
                                    <img src={logo} alt="" />
                                  </div>
                                  <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--cost">
                                    <h2 className="completed">
                                      Payment Completed
                                    </h2>
                                    <p>NPR {value.amount}</p>
                                  </div>
                                </div>
                                <div className="drivergrid-drivercontent--stagediv_payment__card-imagecost--btn">
                                  <button className="completedbtn">
                                    Completed
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                    {stage === 7 && (
                      <div className="drivergrid-drivercontent--stagediv">
                        <div className="drivergrid-drivercontent--stagediv_head">
                          <h2>Send Notification</h2>
                        </div>
                        <form
                          action=""
                          className="form"
                          onSubmit={handleSendNotification}
                          style={{ padding: "1rem" }}
                        >
                          <div>
                            <label htmlFor="title">Title:</label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Enter notification title ..."
                              value={notificationTitle}
                              onChange={(e) =>
                                setNotificationTitle(e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <label htmlFor="message">Message:</label>
                            <textarea
                              name="message"
                              id="message"
                              cols="30"
                              rows="4"
                              placeholder="Enter notification message ..."
                              value={notificationMessage}
                              onChange={(e) =>
                                setNotificationMessage(e.target.value)
                              }
                            ></textarea>
                          </div>

                          <div className="submitbtn">
                            <input
                              type="submit"
                              value="Send"
                              className="primary-button"
                            />
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {selectedDriver.status === "Rejected" ||
                (selectedDriver.status === "Pending" && (
                  <div className="modal-footer">
                    <button
                      onClick={() => setApproveModal(true)}
                      className="primary-button "
                    >
                      Verify Driver
                    </button>
                  </div>
                ))}
            </div>
          </div>
        )}
        {approveModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Verify Driver</h2>
                <i
                  onClick={() => setApproveModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>
                    Warning: Please ensure you have thoroughly reviewed all the
                    driver details before proceeding.
                  </p>
                  <div className="approvediv-btns">
                    <button
                      className="primary-button"
                      onClick={handleProceedButton}
                    >
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setApproveModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {blockModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Block Driver</h2>
                <i
                  onClick={() => setBlockModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>Are you sure you want to change block status?</p>
                  <div className="approvediv-btns">
                    <button
                      className="primary-button"
                      onClick={handleBlockDriver}
                    >
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setBlockModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {approveFormModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "500px" }}>
              <div className="modal-header">
                <h2>Verify Driver</h2>
                <i
                  onClick={() => setApproveFormModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form action="" className="form" onSubmit={handleApproveDriver}>
                  <div>
                    <label htmlFor="status">Status:</label>
                    <select
                      name="status"
                      id="status"
                      className="formselect"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">-- Choose any Status --</option>
                      <option value="Approved">Approve</option>
                      <option value="Rejected">Reject</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="message">Message:</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="10"
                      rows="5"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Verify"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {commissionModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Settle Commission</h2>
                <i
                  onClick={() => setCommissionModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form action="" className="form" onSubmit={handleCommission}>
                  <div>
                    <label htmlFor="commission">Commission Amount:</label>
                    <input
                      type="number"
                      step="0.01"
                      name="commission"
                      id="commission"
                      placeholder="Commission amount ..."
                      onChange={(e) => setCommission(e.target.value)}
                    />
                  </div>

                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Settle"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {settleModal && (
          <div className="overlay" style={{ background: "none" }}>
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Settle Commission</h2>
                <i
                  onClick={() => setSettleModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>Are you sure you want to settle this commission?</p>
                  <div className="approvediv-btns">
                    <button className="primary-button" onClick={handleSettle}>
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setSettleModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ListDriver;
