import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";

import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import {
  addNotification,
  deleteNotification,
  getNotification,
} from "../../redux/thunk/notificationThunk";
import { resetNotification } from "../../redux/slice/notificationSlice";

const ListNotification = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedNotification, setSelectedNotification] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { notification, addedNotificationData, deletedNotificationData } =
    useSelector((state) => state.notificationState);

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  useEffect(() => {
    if (addedNotificationData.result) {
      setAddModal(false);
      setTitle("");
      setMessage("");
      dispatch(resetNotification());
    }
    dispatch(getNotification());
  }, [addedNotificationData.result, dispatch]);

  useEffect(() => {
    if (deletedNotificationData.result) {
      setId("");
      setDeleteModal(false);
      dispatch(resetNotification());
    }
    dispatch(getNotification());
  }, [deletedNotificationData.result, dispatch]);

  const openDeleteModal = (value) => {
    setId(value.notification_id);
    setDeleteModal(true);
  };
  const openNotificationModal = (value) => {
    setSelectedNotification(value);
    setViewModal(true);
  };

  useEffect(() => {
    addModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [addModal]);
  useEffect(() => {
    viewModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [viewModal]);
  useEffect(() => {
    deleteModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [deleteModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Title",
        accessor: (row) => row.title,
      },
      {
        Header: "Message",
        accessor: (row) =>
          row.message?.split(" ")?.slice(0, 15)?.join(" ") + "...",
        headerStyle: {
          width: "300px",
        },
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <i
              className="fa-regular fa-eye actionlinks-view"
              onClick={() => openNotificationModal(row)}
            ></i>
            <i
              className="fa-regular fa-trash  actionlinks-delete"
              onClick={() => openDeleteModal(row)}
            ></i>
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => notification, [notification]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleAddNotification = async (event) => {
    event.preventDefault();

    if (!title) {
      toast.error("Title is Required");
      return false;
    }
    if (!message) {
      toast.error("Message is Required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        title: title,
        message: message,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(addNotification(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteNotification = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(deleteNotification(id));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader isActive={true} />}
      <Layout>
        <div className="listpage">
          <div className="listpage-top">
            <h1>Notification List</h1>
            <div className="listpage-top--textlink">
              <h2>
                <span>
                  <i className="fa-regular fa-bell"></i>
                </span>
                &nbsp;/ Notification Management
              </h2>
              <div className="listpage-top--textlink_addsetting">
                <button
                  className="primary-button"
                  style={{ fontSize: "0.8rem", height: "2.2rem" }}
                  onClick={() => setAddModal(!addModal)}
                >
                  <span>
                    <i className="fa-regular fa-plus"></i>
                  </span>
                  &nbsp;&nbsp; Send Notification
                </button>
              </div>
            </div>
          </div>

          <div className="listpage-searchentry">
            <div className="listpage-searchentry--search">
              <input
                type="search"
                placeholder="Search here..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
              />
              <i className="fa-regular fa-magnifying-glass"></i>
            </div>

            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="listpage-searchentry--pageselect"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={notification.length}>All</option>
            </select>
          </div>

          <div className="listpage-tablesection">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {pageSize !== notification.length && (
              <div className="listpage-tablesection--pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>

        {addModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Send Notification</h2>
                <i
                  onClick={() => setAddModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form
                  action=""
                  className="form"
                  onSubmit={handleAddNotification}
                >
                  <div>
                    <label htmlFor="title">Title:</label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={title}
                      placeholder="Enter notification title ..."
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="message">Message:</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="10"
                      rows="4"
                      placeholder="Enter notification message ..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Send Notification"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {viewModal && selectedNotification && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Notification Detail</h2>
                <i
                  onClick={() => setViewModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="bodyinfo">
                  <p>
                    <strong>Title: </strong>
                    {selectedNotification.title}
                  </p>
                  <p>
                    <strong>Message:</strong>
                  </p>
                  <p>{selectedNotification.message}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {deleteModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Delete Notification</h2>
                <i
                  onClick={() => setDeleteModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>Are you sure you want to delete this notification?</p>
                  <div className="approvediv-btns">
                    <button
                      className="primary-button"
                      onClick={handleDeleteNotification}
                    >
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setDeleteModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ListNotification;
