import { createAsyncThunk } from "@reduxjs/toolkit";
import { doPut } from "../../axios/instance";
import { toast } from "react-toastify";
export const employeePassword = createAsyncThunk(
  "employeePassword",
  async (data) => {
    try {
      const response = await doPut(`system/users/employee-password`, data);

      if (response.status === 200) {
        toast.success("Password Changed Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);
