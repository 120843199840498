import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getCoordinator = createAsyncThunk("getCoordinator", async () => {
  try {
    const response = await doGet(`system/admins`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});

export const addCoordinator = createAsyncThunk(
  "addCoordinator",
  async (data) => {
    try {
      const response = await doPost(`system/admins`, data);

      if (response.status === 200) {
        toast.success("Coordinator Added Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const editCoordinator = createAsyncThunk(
  "editCoordinator",
  async (data) => {
    try {
      const response = await doPut(`system/admins`, data);

      if (response.status === 200) {
        toast.success("Coordinator Edited Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const deleteCoordinator = createAsyncThunk(
  "deleteCoordinator",
  async (id) => {
    try {
      const response = await doDelete(`system/admins/${id}`);

      if (response.status === 200) {
        toast.success("Coordinator Deleted Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);
