import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDriver } from "../../redux/thunk/driverThunk";
import { getCoordinator } from "../../redux/thunk/coordinatorThunk";
import { getUser } from "../../redux/thunk/userThunk";
import { Link } from "react-router-dom";
import { getNotification } from "../../redux/thunk/notificationThunk";
import { getRides } from "../../redux/thunk/ridesThunk";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { coordinator } = useSelector((state) => state.coordinatorState);
  const { driver } = useSelector((state) => state.driverState);
  const { user } = useSelector((state) => state.userState);
  const { notification } = useSelector((state) => state.notificationState);
  const { rides } = useSelector((state) => state.ridesState);

  const [selectedTimeRange, setSelectedTimeRange] = useState("Today");
  const [totalRides, setTotalRides] = useState([]);
  const [completedRides, setCompletedRides] = useState([]);
  const [ongoingRides, setOngoingRides] = useState([]);
  const [cancelledRides, setCancelledRides] = useState([]);

  useEffect(() => {
    dispatch(getCoordinator());
    dispatch(getDriver());
    dispatch(getUser());
    dispatch(getNotification());
    dispatch(getRides());
    setSelectedTimeRange("Today");
  }, [dispatch]);
  const filterRides = (selectedTimeRange) => {
    const now = new Date();
    const ridesData = rides;
    const oneDay = 24 * 60 * 60 * 1000;
    let totalRides = [];
    let completedRides = [];
    let ongoingRides = [];
    let cancelledRides = [];

    switch (selectedTimeRange) {
      case "Today":
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        totalRides = ridesData.filter(
          (ride) => new Date(ride.created_at) >= today
        );
        break;
      case "7days":
        const sevenDaysAgo = new Date(now.getTime() - 7 * oneDay);
        totalRides = ridesData.filter(
          (ride) => new Date(ride.created_at) >= sevenDaysAgo
        );
        break;
      case "30days":
        const thirtyDaysAgo = new Date(now.getTime() - 30 * oneDay);
        totalRides = ridesData.filter(
          (ride) => new Date(ride.created_at) >= thirtyDaysAgo
        );
        break;
      case "1year":
        const oneYearAgo = new Date(now.getTime() - 365 * oneDay);
        totalRides = ridesData.filter(
          (ride) => new Date(ride.created_at) >= oneYearAgo
        );
        break;
      default:
        totalRides = ridesData;
    }

    completedRides = totalRides.filter(
      (ride) => ride.status === "COMPLETED" || ride.status === "REVIEW"
    );
    ongoingRides = totalRides.filter(
      (ride) =>
        ride.status !== "COMPLETED" &&
        ride.status !== "REVIEW" &&
        ride.status !== "CANCELLED"
    );
    cancelledRides = totalRides.filter((ride) => ride.status === "CANCELLED");

    setTotalRides(totalRides);
    setCompletedRides(completedRides);
    setOngoingRides(ongoingRides);
    setCancelledRides(cancelledRides);
  };

  const calculateTotalPrice = (rides) =>
    rides.reduce((total, ride) => total + ride.price, 0);
  const calculateTotalCommission = (rides) =>
    rides.reduce((total, ride) => total + (ride.commission || 0), 0);

  useEffect(() => {
    filterRides(selectedTimeRange);
  }, [selectedTimeRange, rides]);
  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard-top">
          <h1>Dashboard</h1>
          <h2>
            <span>
              <i className="fa-regular fa-house"></i>
            </span>
            &nbsp;/ Dashboard
          </h2>
        </div>

        <div className="dashboard-analytics">
          <Link to="/coordinator" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-user-plus"></i>
            </div>
            <h2>Coordinators</h2>
            <h1>{coordinator.length}</h1>
          </Link>
          <Link to="/user" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-users"></i>
            </div>
            <h2>Users</h2>
            <h1>{user.length}</h1>
          </Link>

          <Link to="/driver" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-user-police"></i>
            </div>
            <h2>Drivers</h2>
            <h1>{driver.length}</h1>
          </Link>

          <Link to="/notification" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-bell"></i>
            </div>
            <h2>Notifications</h2>
            <h1>{notification.length}</h1>
          </Link>
        </div>

        <div className="dashboard-rideanalytics">
          <div className="dashboard-rideanalytics--head">
            <h2>Ride Analytics</h2>

            <select
              name="timeRange"
              id="timeRange"
              value={selectedTimeRange}
              onChange={(e) => setSelectedTimeRange(e.target.value)}
            >
              <option value="Today">Today</option>
              <option value="7days">Past 7 Days</option>
              <option value="30days">Past 30 Days</option>
              <option value="1year">Past 1 Year</option>
              <option value="alltime">All Time</option>
            </select>
          </div>
          <div className="dashboard-rideanalytics--ride">
            <div className="dashboard-rideanalytics--ride_rideitem">
              <h2 style={{ color: "#003060" }}>Total Rides</h2>
              <p>{totalRides.length}</p>
              <h3>Rs {calculateTotalPrice(totalRides).toFixed(2)}</h3>
              <h4>Rs {calculateTotalCommission(totalRides).toFixed(2)}</h4>
            </div>

            <div className="dashboard-rideanalytics--ride_rideitem">
              <h2 style={{ color: "#044b37" }}>Completed Rides</h2>
              <p>{completedRides.length}</p>
              <h3>Rs {calculateTotalPrice(completedRides).toFixed(2)}</h3>
              <h4>Rs {calculateTotalCommission(completedRides).toFixed(2)}</h4>
            </div>

            <div className="dashboard-rideanalytics--ride_rideitem">
              <h2 style={{ color: "#df871b" }}>Ongoing Rides</h2>
              <p>{ongoingRides.length}</p>
              <h3>Rs {calculateTotalPrice(ongoingRides).toFixed(2)}</h3>
              <h4>Rs {calculateTotalCommission(ongoingRides).toFixed(2)}</h4>
            </div>

            <div className="dashboard-rideanalytics--ride_rideitem">
              <h2 style={{ color: "#fb3535" }}>Cancelled Rides</h2>
              <p>{cancelledRides.length}</p>
              <h3>Rs {calculateTotalPrice(cancelledRides).toFixed(2)}</h3>
              <h4>Rs {calculateTotalCommission(cancelledRides).toFixed(2)}</h4>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
