import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getUser = createAsyncThunk("getUser", async () => {
  try {
    const response = await doGet(`system/users`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});

export const blockUser = createAsyncThunk("blockUser", async (data) => {
  try {
    const response = await doPut(`system/users/flag`, data);
    if (response.status === 200) {
      toast.success("User Blocked Status Changed Successfully");
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});
