import { createSlice } from "@reduxjs/toolkit";
import {
  addCoordinator,
  deleteCoordinator,
  editCoordinator,
  getCoordinator,
} from "../thunk/coordinatorThunk";

const initialState = {
  coordinator: [],
  addedCoordinatorData: [],
  editedCoordinatorData: [],
  deletedCoordinatorData: [],
  loading: false,
  success: false,
  error: null,
};

const coordinatorSlice = createSlice({
  name: "coordinator",
  initialState,
  reducers: {
    resetCoordinator: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoordinator.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getCoordinator.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.coordinator = action.payload;
        state.error = null;
      })
      .addCase(getCoordinator.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addCoordinator.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addCoordinator.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.addedCoordinatorData = action.payload;
        state.error = null;
      })
      .addCase(addCoordinator.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editCoordinator.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editCoordinator.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.editedCoordinatorData = action.payload;
        state.error = null;
      })
      .addCase(editCoordinator.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteCoordinator.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteCoordinator.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.deletedCoordinatorData = action.payload;
        state.error = null;
      })
      .addCase(deleteCoordinator.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetCoordinator } = coordinatorSlice.actions;
export default coordinatorSlice.reducer;
