import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getDriver = createAsyncThunk("getDriver", async () => {
  try {
    const response = await doGet(`system/drivers`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});

export const getDriverCommission = createAsyncThunk(
  "getDriverCommission",
  async (id) => {
    try {
      const response = await doGet(`/commissions/${id}`);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const approveDriver = createAsyncThunk("approveDriver", async (data) => {
  try {
    const response = await doPut(`system/drivers/verify`, data);
    if (response.status === 200) {
      toast.success("Driver Status Changed Successfully");
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});

export const settleCommission = createAsyncThunk(
  "settleCommission",
  async (data) => {
    try {
      const response = await doPost(`/commissions`, data);
      if (response.status === 200) {
        toast.success("Commission Payment Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const sendNotification = createAsyncThunk(
  "sendNotification",
  async (data) => {
    try {
      const response = await doPost(`/notifications/driver`, data);
      if (response.status === 200) {
        toast.success("Notification Sent Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const blockDriver = createAsyncThunk("blockDriver", async (data) => {
  try {
    const response = await doPut(`system/drivers/flag`, data);
    if (response.status === 200) {
      toast.success("Driver Blocked Status Changed Successfully");
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});
