import { createSlice } from "@reduxjs/toolkit";

import {
  approveDriver,
  blockDriver,
  getDriver,
  getDriverCommission,
  sendNotification,
  settleCommission,
} from "../thunk/driverThunk";

const initialState = {
  driver: [],
  driverCommission: [],
  settleCommissionData: [],
  statusDriverData: [],
  blockedDriverData: [],
  notificationData: [],
  loading: false,
  success: false,
  error: null,
};

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    resetDriver: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriver.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.driver = action.payload;
        state.error = null;
      })
      .addCase(getDriver.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(approveDriver.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(approveDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.statusDriverData = action.payload;
        state.error = null;
      })
      .addCase(approveDriver.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(blockDriver.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blockDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blockedDriverData = action.payload;
        state.error = null;
      })
      .addCase(blockDriver.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getDriverCommission.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getDriverCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.driverCommission = action.payload;
        state.error = null;
      })
      .addCase(getDriverCommission.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(settleCommission.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(settleCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.settleCommissionData = action.payload;
        state.error = null;
      })
      .addCase(settleCommission.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notificationData = action.payload;
        state.error = null;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetDriver } = driverSlice.actions;
export default driverSlice.reducer;
