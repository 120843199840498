import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slice/loginSlice";
import sidebarReducer from "./slice/sidebarSlice";
import coordinatorReducer from "./slice/coordinatorSlice";
import notificationReducer from "./slice/notificationSlice";
import driverReducer from "./slice/driverSlice";
import userReducer from "./slice/userSlice";
import ridesReducer from "./slice/ridesSlice";
import employeePasswordReducer from "./slice/employeePasswordSlice";
import adminPasswordReducer from "./slice/adminPasswordSlice";
import configurationReducer from "./slice/configurationSlice";
const store = configureStore({
  reducer: {
    loginState: loginReducer,
    sidebarState: sidebarReducer,
    coordinatorState: coordinatorReducer,
    notificationState: notificationReducer,
    driverState: driverReducer,
    userState: userReducer,
    ridesState: ridesReducer,
    employeePasswordState: employeePasswordReducer,
    adminPasswordState: adminPasswordReducer,
    configurationState: configurationReducer,
  },
});

export default store;
