import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost } from "../../axios/instance";
import { toast } from "react-toastify";

export const getNotification = createAsyncThunk("getNotification", async () => {
  try {
    const response = await doGet(`/notifications`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});

export const addNotification = createAsyncThunk(
  "addNotification",
  async (data) => {
    try {
      const response = await doPost(`/notifications`, data);

      if (response.status === 200) {
        toast.success("Notification Sent Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (id) => {
    try {
      const response = await doDelete(`notifications/${id}`);

      if (response.status === 200) {
        toast.success("Notification Deleted Successfully");
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error.response?.data?.error || error.response.data.issues[0].message
        );
      }
      throw error;
    }
  }
);
