import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";
import { getRides } from "../../redux/thunk/ridesThunk";
import { Link, useParams } from "react-router-dom";

import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import { apiKey } from "../../utils/env";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const ListRides = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const [search, setSearch] = useState("");
  const [rideModal, setRideModal] = useState(false);
  const [selectedRide, setSelectedRide] = useState("");
  const [filteredRideData, setFilteredRideData] = useState([]);
  const [mapData, setMapData] = useState({
    origin: { lat: 0, lng: 0, name: "Origin" },
    destination: { lat: 0, lng: 0, name: "Destination" },
  });
  const { id } = useParams();
  const { rides } = useSelector((state) => state.ridesState);
  const [filter, setFilter] = useState({
    date: null,
    status: null,
  });
  useEffect(() => {
    dispatch(getRides());
  }, [dispatch]);

  const getStatusBackgroundColor = (status) => {
    if (status === "CANCELLED") {
      return "#fb3535";
    } else if (status === "COMPLETED" || status === "REVIEW") {
      return "#044b37";
    } else {
      return "#df871b";
    }
  };

  const filterRides = (rides, id, filter) => {
    if (!Array.isArray(rides)) {
      return [];
    }
    return rides
      ?.filter((ride) =>
        id ? ride.driver_id === id || ride.user_id === id : true
      )
      .filter((i) => {
        if (filter.status === "CANCELLED") {
          return i.status === "CANCELLED";
        } else if (filter.status === "COMPLETED") {
          return i.status === "COMPLETED" || i.status === "REVIEW";
        } else if (filter.status === "ONGOING") {
          return (
            i.status !== "CANCELLED" &&
            i.status !== "REVIEW" &&
            i.status !== "COMPLETED"
          );
        } else {
          return true;
        }
      })
      .filter((i) => {
        if (filter.date) {
          const createdAt = moment(i.created_at).startOf("day");
          const today = moment().startOf("day");
          if (filter.date === "Today") {
            return createdAt.isSame(today, "day");
          } else if (filter.date === "Last Week") {
            const lastWeek = moment().subtract(1, "week").startOf("day");
            return createdAt.isBetween(lastWeek, today, null, "[]");
          } else if (filter.date === "Last Month") {
            const lastMonth = moment().subtract(1, "month").startOf("day");
            return createdAt.isBetween(lastMonth, today, null, "[]");
          } else if (filter.date === "Last Year") {
            const lastYear = moment().subtract(1, "year").startOf("day");
            return createdAt.isBetween(lastYear, today, null, "[]");
          } else {
            return true;
          }
        }
        return true;
      });
  };

  useEffect(() => {
    setFilteredRideData(filterRides(rides, id, filter));
  }, [rides, id, filter]);

  const openRideModal = (value) => {
    setMapData({
      origin: {
        lat: parseFloat(value.origin_lat),
        lng: parseFloat(value.origin_lng),
        name: value.origin_name,
      },
      destination: {
        lat: parseFloat(value.destination_lat),
        lng: parseFloat(value.destination_lng),
        name: value.destination_name,
      },
    });

    setSelectedRide(value);
    setRideModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Time",
        accessor: (row) => moment(row.created_at).format("lll"),
        headerStyle: { width: "120px" },
        Cell: ({ value }) => <strong>{value}</strong>,
      },

      {
        Header: "Location",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3rem",
              }}
            >
              <strong
                style={{
                  background: "#1b1b1b",
                  color: "white",
                  borderRadius: "4px",
                  padding: "0.2rem",
                  fontSize: "0.9rem",
                  width: "fit-content",
                }}
              >
                Origin:
              </strong>
              <span>{row.origin_name}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3rem",
              }}
            >
              <strong
                style={{
                  background: "#1b1b1b",
                  color: "white",
                  borderRadius: "4px",
                  padding: "0.2rem",
                  fontSize: "0.9rem",
                  width: "fit-content",
                }}
              >
                Destination:
              </strong>

              <span>{row.destination_name}</span>
            </div>
          </div>
        ),
      },
      {
        Header: "Participant",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <strong
                style={{
                  background: "#044b37",
                  color: "white",
                  borderRadius: "4px",
                  padding: "0.2rem",
                  fontSize: "0.9rem",
                }}
              >
                Driver:
              </strong>
              {"   "}
              <Link
                to={`/driver/${row.driver_id}`}
                style={{ color: "black", textTransform: "capitalize" }}
              >
                {row.driver}
              </Link>
            </div>
            <div style={{ whiteSpace: "nowrap" }}>
              <strong
                style={{
                  background: "#044b37",
                  color: "white",
                  borderRadius: "4px",
                  padding: "0.2rem",
                  fontSize: "0.9rem",
                }}
              >
                Passenger:
              </strong>
              {"   "}
              <Link
                to={`/user/${row.user_id}`}
                style={{ color: "black", textTransform: "capitalize" }}
              >
                {row.user}
              </Link>
            </div>
          </div>
          // <div
          //   style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          // >
          //   <div
          //     style={{
          //       display: "flex",
          //       flexDirection: "column",
          //       gap: "0.3rem",
          //     }}
          //   >
          //     <strong
          //       style={{
          //         background: "#044b37",
          //         color: "white",
          //         borderRadius: "4px",
          //         padding: "0.2rem",
          //         fontSize: "0.9rem",
          //         width: "fit-content",
          //       }}
          //     >
          //       Driver:
          //     </strong>
          //     <span>{row.driver}</span>
          //   </div>
          //   <div
          //     style={{
          //       display: "flex",
          //       flexDirection: "column",
          //       gap: "0.3rem",
          //     }}
          //   >
          //     <strong
          //       style={{
          //         background: "#044b37",
          //         color: "white",
          //         borderRadius: "4px",
          //         padding: "0.2rem",
          //         fontSize: "0.9rem",
          //         width: "fit-content",
          //       }}
          //     >
          //       User:
          //     </strong>

          //     <span>{row.user}</span>
          //   </div>
          // </div>
        ),
      },

      {
        Header: "Distance",
        accessor: (row) => row.distance / 1000 + " Km",
      },
      {
        Header: "Price",
        accessor: (row) => "Rs " + row.price.toFixed(2),
        headerStyle: { width: "150px" },
        Cell: ({ value }) => <div style={{ color: "green" }}>{value}</div>,
      },
      {
        Header: "Commission",
        accessor: (row) => "Rs " + row.commission.toFixed(2),
        headerStyle: { width: "150px" },
        Cell: ({ value }) => <div style={{ color: "red" }}>{value}</div>,
      },
      {
        Header: "Status",
        accessor: (row) => (
          <span
            style={{
              backgroundColor: getStatusBackgroundColor(row.status),
            }}
            className="status"
          >
            {row.status === "CANCELLED"
              ? "CANCELLED"
              : row.status === "COMPLETED" || row.status === "REVIEW"
              ? "COMPLETED"
              : "ONGOING"}
          </span>
        ),
      },

      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <i
              className="fa-regular fa-eye actionlinks-view"
              onClick={() => openRideModal(row)}
            ></i>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredRideData,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const [activeMarker, setActiveMarker] = useState(null);
  const [openMarker, setOpenMarker] = useState(false);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    setTimeout(() => {
      setOpenMarker(true);
    }, 500);

    const bounds = new window.google.maps.LatLngBounds();

    bounds.extend(
      new window.google.maps.LatLng(mapData.origin.lat, mapData.origin.lng)
    );
    bounds.extend(
      new window.google.maps.LatLng(
        mapData.destination.lat,
        mapData.destination.lng
      )
    );
    map.fitBounds(bounds);
  };

  const customMarker = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "red",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };
  const finishFlagMarker = {
    path: "M5 1C4.44772 1 4 1.44772 4 2V22C4 22.5523 4.44772 23 5 23C5.55228 23 6 22.5523 6 22V15.693L18.8542 10.8727C20.5846 10.2238 20.5846 7.77627 18.8542 7.12739L6 2.30705V2C6 1.44772 5.55228 1 5 1ZM6 4.44305V13.557L17.6526 9.18732C17.8256 9.12243 17.8256 8.87767 17.6526 8.81278L6 4.44305Z",
    fillColor: "red",
    fillOpacity: 1,
    strokeColor: "black",
    strokeWeight: 1,
    scale: 1.5,
  };

  return (
    <>
      <Layout>
        <div className="listpage">
          <div className="listpage-top">
            <h1>Rides List</h1>
            <div className="listpage-top--textlink">
              <h2>
                <span>
                  <i className="fa-regular fa-car"></i>
                </span>
                &nbsp;/ Rides Management
              </h2>
              <div className="listpage-top--textlink_addsetting"></div>
            </div>
          </div>

          <div className="listpage-searchentry">
            <div className="listpage-searchentry--search">
              <input
                type="search"
                placeholder="Search here..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
              />
              <i className="fa-regular fa-magnifying-glass"></i>
            </div>
            <div className="listpage-searchentry--selects">
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <p>Date: </p>
                <select
                  className="listpage-searchentry--selects_filterselect"
                  name="date"
                  onChange={(e) =>
                    setFilter({ ...filter, date: e.target.value })
                  }
                >
                  <option value="">All</option>
                  <option value="Today">Today</option>
                  <option value="Last Week">Upto Last Week</option>
                  <option value="Last Month">Upto Last Month</option>
                  <option value="Last Year">Upto Last Year</option>
                </select>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <p>Status: </p>
                <select
                  className="listpage-searchentry--selects_filterselect"
                  name="status"
                  onChange={(e) =>
                    setFilter({ ...filter, status: e.target.value })
                  }
                >
                  <option value="">All</option>
                  <option value="ONGOING">Ongoing</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="CANCELLED">Cancelled</option>
                </select>
              </div>

              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="listpage-searchentry--selects_pageselect"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={filteredRideData.length}>All</option>
              </select>
            </div>
          </div>

          <div className="listpage-tablesection" style={{ overflowX: "auto" }}>
            <table {...getTableProps()} style={{ minWidth: "100%" }}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {pageSize !== filteredRideData.length && (
              <div className="listpage-tablesection--pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>
        {rideModal && selectedRide && (
          <div className="overlay">
            <div className="modal" style={{ width: "1000px" }}>
              <div className="modal-header">
                <h2>Ride Details</h2>
                <i
                  onClick={() => {
                    setRideModal(false);
                    setActiveMarker(null);
                  }}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="ridedetail">
                  <div className="ridedetail-detail">
                    {selectedRide.remark && (
                      <p
                        style={{
                          background: "#df871b",
                          color: "white",
                          width: "fit-content",
                          padding: "0.2rem 1rem",
                          borderRadius: "8px",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <strong>Remarks: </strong>
                        {selectedRide.remark}
                      </p>
                    )}
                    <p>
                      <strong>Status: </strong>
                      <span
                        style={{
                          backgroundColor: getStatusBackgroundColor(
                            selectedRide.status
                          ),
                        }}
                        className="status"
                      >
                        {selectedRide.status === "CANCELLED"
                          ? "CANCELLED"
                          : selectedRide.status === "COMPLETED" ||
                            selectedRide.status === "REVIEW"
                          ? "COMPLETED"
                          : "ONGOING"}
                      </span>
                    </p>

                    <p>
                      <strong>Driver: </strong>
                      <Link
                        to={`/driver/${selectedRide.driver_id}`}
                        style={{ color: "black", textTransform: "capitalize" }}
                      >
                        {selectedRide.driver}
                      </Link>
                    </p>
                    <p>
                      <strong>User: </strong>
                      <Link
                        to={`/user/${selectedRide.user_id}`}
                        style={{ color: "black", textTransform: "capitalize" }}
                      >
                        {selectedRide.user}
                      </Link>
                    </p>
                    {selectedRide.cancelled_by && (
                      <p>
                        <strong>Cancelled By: </strong>
                        {selectedRide.cancelled_by}
                      </p>
                    )}
                    <p>
                      <strong>Origin: </strong>
                      {selectedRide.origin_name}
                    </p>
                    <p>
                      <strong>Destination: </strong>
                      {selectedRide.destination_name}
                    </p>
                    <p>
                      <strong>Distance: </strong>
                      {selectedRide.distance / 1000}
                      {" Km"}
                    </p>
                    <p>
                      <strong>Price: </strong>
                      {"Rs "}
                      {selectedRide.price.toFixed(2)}
                    </p>
                    <p>
                      <strong>Commission: </strong>
                      {"Rs "}
                      {selectedRide.commission.toFixed(2)}
                    </p>
                    <p>
                      <strong>Discount: </strong>
                      {"Rs "}
                      {selectedRide.discount.toFixed(2)}
                    </p>
                    {selectedRide.start_time && (
                      <p>
                        <strong>Start Time: </strong>
                        {moment(selectedRide.start_time).format("lll")}
                      </p>
                    )}
                    {selectedRide.end_time && (
                      <p>
                        <strong>End Time: </strong>
                        {moment(selectedRide.end_time).format("lll")}
                      </p>
                    )}
                  </div>
                  <div className="ridedetail-map">
                    {isLoaded && (
                      <GoogleMap
                        onLoad={handleOnLoad}
                        clickableIcons={false}
                        mapContainerStyle={containerStyle}
                        onUnmount={() => setOpenMarker(false)}
                        center={{
                          lat:
                            (mapData.origin.lat + mapData.destination.lat) / 2,
                          lng:
                            (mapData.origin.lng + mapData.destination.lng) / 2,
                        }}
                        zoom={14}
                      >
                        {openMarker ? (
                          <Marker
                            position={{
                              lat: mapData.origin.lat,
                              lng: mapData.origin.lng,
                            }}
                            onClick={() => handleActiveMarker("origin")}
                            key={1}
                            icon={customMarker}
                          >
                            {activeMarker === "origin" ? (
                              <InfoWindowF
                                onCloseClick={() => setActiveMarker(null)}
                              >
                                <div className="place">
                                  Origin: {mapData.origin.name}
                                </div>
                              </InfoWindowF>
                            ) : null}
                          </Marker>
                        ) : null}
                        {openMarker ? (
                          <Marker
                            position={{
                              lat: mapData.destination.lat,
                              lng: mapData.destination.lng,
                            }}
                            onClick={() => handleActiveMarker("destination")}
                            key={0}
                            icon={finishFlagMarker}
                          >
                            {activeMarker === "destination" ? (
                              <InfoWindowF
                                onCloseClick={() => setActiveMarker(null)}
                              >
                                <div className="place">
                                  Destination: {mapData.destination.name}
                                </div>
                              </InfoWindowF>
                            ) : null}
                          </Marker>
                        ) : null}
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ListRides;
