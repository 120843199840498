import { createSlice } from "@reduxjs/toolkit";

import {
  addConfiguration,
  getConfiguration,
} from "../thunk/configurationThunk";

const initialState = {
  configurationData: [],
  addedConfigurationData: [],
  loading: false,
  success: false,
  error: null,
};

const configurationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetConfiguration: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfiguration.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.configurationData = action.payload;
        state.error = null;
      })
      .addCase(getConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addConfiguration.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.addedConfigurationData = action.payload;
        state.error = null;
      })
      .addCase(addConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetConfiguration } = configurationSlice.actions;
export default configurationSlice.reducer;
