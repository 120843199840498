// Layout.js
import React from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../redux/slice/sidebarSlice";

const Layout = ({ children }) => {
  const isSidebarOpen = useSelector(
    (state) => state.sidebarState.isSidebarOpen
  );
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <div className={`layout ${isSidebarOpen ? "" : "collapsed"}`}>
      <div className="layout-sidebar">
        <Sidebar />
      </div>
      <div className="layout-topheadercontent">
        <div className="layout-topheadercontent--topheader">
          <TopHeader onSidebarToggle={handleSidebarToggle} />
        </div>
        <div className="layout-topheadercontent--content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
