import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";

import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import { resetCoordinator } from "../../redux/slice/coordinatorSlice";
import { employeePassword } from "../../redux/thunk/employeePasswordThunk";
import { resetEmployeePassword } from "../../redux/slice/employeePasswordSlice";
import {
  addConfiguration,
  getConfiguration,
} from "../../redux/thunk/configurationThunk";
import { resetConfiguration } from "../../redux/slice/configurationSlice";
import {
  addCoordinator,
  deleteCoordinator,
  editCoordinator,
  getCoordinator,
} from "../../redux/thunk/coordinatorThunk";

const ListCoordinator = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [editUsername, setEditUsername] = useState("");
  const [editRole, setEditRole] = useState("");
  const [id, setId] = useState("");
  const [newPasswordType, setNewPasswordType] = useState(true);
  const [addPasswordType, setAddPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentConfig, setCurrentConfig] = useState({
    type: "",
    value: "",
  });

  const {
    coordinator,
    addedCoordinatorData,
    editedCoordinatorData,
    deletedCoordinatorData,
  } = useSelector((state) => state.coordinatorState);
  const { employeePasswordData } = useSelector(
    (state) => state.employeePasswordState
  );

  const { configurationData, addedConfigurationData } = useSelector(
    (state) => state.configurationState
  );

  const [addUserData, setAddUserData] = useState({
    username: "",
    addpassword: "",
    role: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddUserData({
      ...addUserData,
      [name]: value,
    });
  };

  const handleConfigurationChange = (e, type) => {
    const newValue = e.target.value;
    setCurrentConfig({
      type: type,
      value: newValue,
    });
  };

  useEffect(() => {
    dispatch(getCoordinator());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (addedCoordinatorData.result) {
      setAddModal(false);
      setAddUserData({
        username: "",
        addpassword: "",
        role: "",
      });
      dispatch(resetCoordinator());
    }
    dispatch(getCoordinator());
  }, [addedCoordinatorData.result, dispatch]);

  useEffect(() => {
    if (addedConfigurationData.result) {
      setSettingModal(false);
      setCurrentConfig({
        type: "",
        value: "",
      });
      dispatch(resetConfiguration());
    }
    dispatch(getConfiguration());
  }, [addedConfigurationData.result, dispatch]);

  useEffect(() => {
    if (employeePasswordData.result) {
      setPasswordModal(false);
      setNewPassword("");
      setConfirmPassword("");
      setId("");
      dispatch(resetEmployeePassword());
    }
    dispatch(getCoordinator());
  }, [employeePasswordData.result, dispatch]);

  useEffect(() => {
    if (editedCoordinatorData.result) {
      setEditModal(false);
      setEditUsername("");
      setEditRole("");
      setId("");
      dispatch(resetCoordinator());
    }
    dispatch(getCoordinator());
  }, [editedCoordinatorData.result, dispatch]);

  useEffect(() => {
    if (deletedCoordinatorData.result) {
      setId("");
      setDeleteModal(false);
      dispatch(resetCoordinator());
    }
    dispatch(getCoordinator());
  }, [deletedCoordinatorData.result, dispatch]);

  useEffect(() => {
    passwordModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [passwordModal]);
  useEffect(() => {
    addModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [addModal]);
  useEffect(() => {
    editModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [editModal]);
  useEffect(() => {
    deleteModal
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [deleteModal]);

  const openPasswordModal = (value) => {
    setSelectedUser(value);
    setId(value.system_user_id);
    setPasswordModal(true);
  };
  const openEditModal = (value) => {
    setSelectedUser(value);
    setEditRole(value.role);
    setId(value.system_user_id);
    setEditModal(true);
  };

  const openDeleteModal = (value) => {
    setId(value.system_user_id);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Username",
        accessor: (row) => row.username,
      },
      {
        Header: "Role",
        accessor: (row) => row.role,
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Action",
        accessor: (row) => {
          const isAdmin = row.role === "Admin";
          const isAdminIDMatch =
            row.system_user_id ===
            JSON.parse(localStorage.getItem("userDetails")).system_user_id;

          if (isAdmin && !isAdminIDMatch) {
            return "N/A";
          } else {
            return (
              <div className="actionlinks">
                <i
                  className="fa-regular fa-edit actionlinks-edit"
                  onClick={() => openEditModal(row)}
                ></i>
                {row.role === "Employee" && (
                  <>
                    <i
                      className="fa-regular fa-key actionlinks-password"
                      onClick={() => openPasswordModal(row)}
                    ></i>
                    <i
                      className="fa-regular fa-trash actionlinks-delete"
                      onClick={() => openDeleteModal(row)}
                    ></i>
                  </>
                )}
              </div>
            );
          }
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => coordinator, [coordinator]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleAddUser = async (event) => {
    event.preventDefault();

    if (!addUserData.username) {
      toast.error("Username is Required");
      return false;
    }
    if (!addUserData.addpassword) {
      toast.error("Password is Required");
      return false;
    }
    if (!addUserData.role) {
      toast.error("Please Select Any Role");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        username: addUserData.username,
        password: addUserData.addpassword,
        role: addUserData.role,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(addCoordinator(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(deleteCoordinator(id));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async (event) => {
    event.preventDefault();

    if (!editUsername) {
      toast.error("Username is Required");
      return false;
    }
    if (!editRole) {
      toast.error("Please Select Any Role");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        system_user_id: id,
        username: editUsername,
        role: editRole,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(editCoordinator(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeePassword = async (event) => {
    event.preventDefault();

    if (!newPassword) {
      toast.error("New Password is Required");
      return false;
    }
    if (!confirmPassword) {
      toast.error("Confirm Password is Required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        system_user_id: id,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(employeePassword(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSaveConfiguration = async (event, type) => {
    event.preventDefault();
    if (!currentConfig.value) {
      toast.error(`${type} is Required`);
      return false;
    }

    try {
      setLoading(true);
      const data = {
        type: type,
        value: currentConfig.value,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(addConfiguration(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader isActive={true} />}
      <Layout>
        <div className="listpage">
          <div className="listpage-top">
            <h1>Coordinator List</h1>
            <div className="listpage-top--textlink">
              <h2>
                <span>
                  <i className="fa-regular fa-user-plus"></i>
                </span>
                &nbsp;/ Coordinator Management
              </h2>
              <div className="listpage-top--textlink_addsetting">
                <button
                  className="primary-button"
                  style={{ fontSize: "0.8rem", height: "2.2rem" }}
                  onClick={() => setAddModal(!addModal)}
                >
                  <span>
                    <i className="fa-regular fa-plus"></i>
                  </span>
                  &nbsp;&nbsp; Add Coordinator
                </button>
                <i
                  className="fa-regular fa-gear listpage-top--textlink_addsetting__setting"
                  onClick={() => setSettingModal(!settingModal)}
                ></i>
              </div>
            </div>
          </div>

          <div className="listpage-searchentry">
            <div className="listpage-searchentry--search">
              <input
                type="search"
                placeholder="Search here..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
              />
              <i className="fa-regular fa-magnifying-glass"></i>
            </div>

            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="listpage-searchentry--pageselect"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={coordinator.length}>All</option>
            </select>
          </div>

          <div className="listpage-tablesection">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {pageSize !== coordinator.length && (
              <div className="listpage-tablesection--pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            )}
          </div>
        </div>
        {passwordModal && selectedUser && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Change Password</h2>
                <i
                  onClick={() => setPasswordModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form
                  action=""
                  className="form"
                  onSubmit={handleEmployeePassword}
                >
                  <div>
                    <label htmlFor="newpassword">New Password:</label>
                    <div className="passwordbutton">
                      <input
                        type={newPasswordType ? "password" : "text"}
                        name="newpassword"
                        id="newpassword"
                        placeholder="Enter new password ..."
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setNewPasswordType(!newPasswordType);
                        }}
                        className="passwordbutton-button"
                      >
                        {newPasswordType ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="confirmpassword">Confirm Password:</label>
                    <div className="passwordbutton">
                      <input
                        type={confirmPasswordType ? "password" : "text"}
                        name="confirmpassword"
                        id="confirmpassword"
                        placeholder="Retype new password ..."
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmPasswordType(!confirmPasswordType);
                        }}
                        className="passwordbutton-button"
                      >
                        {confirmPasswordType ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Update"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
              {/* <div className="modal-footer">
              <button
                onClick={() => setPasswordModal(false)}
                className="modal-footer--btn"
              >
                Close
              </button>
            </div> */}
            </div>
          </div>
        )}

        {addModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Add Coordinator</h2>
                <i
                  onClick={() => setAddModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form action="" className="form" onSubmit={handleAddUser}>
                  <div>
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      value={addUserData.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="addpassword">Password:</label>
                    <div className="passwordbutton">
                      <input
                        type={addPasswordType ? "password" : "text"}
                        name="addpassword"
                        id="addpassword"
                        value={addUserData.addpassword}
                        onChange={handleInputChange}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setAddPasswordType(!addPasswordType);
                        }}
                        className="passwordbutton-button"
                      >
                        {addPasswordType ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="role">Role:</label>
                    <select
                      name="role"
                      id="role"
                      className="formselect"
                      value={addUserData.role}
                      onChange={handleInputChange}
                    >
                      <option value="">-- Choose any role --</option>
                      <option value="Admin">Admin</option>
                      <option value="Employee">Employee</option>
                    </select>
                  </div>
                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Add"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
              {/* <div className="modal-footer">
              <button
                onClick={() => setPasswordModal(false)}
                className="modal-footer--btn"
              >
                Close
              </button>
            </div> */}
            </div>
          </div>
        )}

        {editModal && selectedUser && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Edit Coordinator</h2>
                <i
                  onClick={() => setEditModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form action="" className="form" onSubmit={handleEditUser}>
                  <div>
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      defaultValue={selectedUser.username}
                      onChange={(e) => setEditUsername(e.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="role">Role:</label>
                    <select
                      name="role"
                      id="role"
                      className="formselect"
                      value={editRole}
                      onChange={(e) => setEditRole(e.target.value)}
                    >
                      <option value="Admin">Admin</option>
                      <option value="Employee">Employee</option>
                    </select>
                  </div>
                  <div className="submitbtn">
                    <input
                      type="submit"
                      value="Edit"
                      className="primary-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {deleteModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "400px" }}>
              <div className="modal-header">
                <h2>Delete User</h2>
                <i
                  onClick={() => setDeleteModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <div className="approvediv">
                  <i className="fa-regular fa-circle-exclamation"></i>
                  <p>Are you sure you want to delete this user?</p>
                  <div className="approvediv-btns">
                    <button
                      className="primary-button"
                      onClick={handleDeleteUser}
                    >
                      Proceed
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setDeleteModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {settingModal && (
          <div className="overlay">
            <div className="modal" style={{ width: "600px" }}>
              <div className="modal-header">
                <h2>Configuration Settings</h2>
                <i
                  onClick={() => setSettingModal(false)}
                  className="fa-regular fa-times"
                ></i>
              </div>

              <div className="modal-body">
                <form action="" className="form">
                  {configurationData.map((config) => (
                    <div key={config.type}>
                      <label
                        htmlFor={config.type}
                        style={{ fontWeight: "600" }}
                      >
                        {config.type}:
                      </label>
                      <div
                        className="input"
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          justifyContent: "stretch",
                        }}
                      >
                        <input
                          type="text"
                          name={config.type}
                          id={config.type}
                          defaultValue={config.value}
                          onChange={(e) =>
                            handleConfigurationChange(e, config.type)
                          }
                        />
                        <input
                          type="submit"
                          value="Save"
                          style={{
                            background: "#003060",
                            color: "white",
                            height: "3.2rem",
                            width: "8rem",
                            fontSize: "1rem",
                            borderRadius: "4px",
                            border: "none",
                          }}
                          onClick={(e) =>
                            handleSaveConfiguration(e, config.type)
                          }
                        />
                      </div>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          padding: "0.25rem 0rem",
                          fontWeight: "500",
                        }}
                      >
                        Note: {config.message}
                      </p>
                      {/* <div className="submitbtn">
                        <input
                          type="submit"
                          value="Save"
                          className="primary-button"
                          onClick={(e) =>
                            handleSaveConfiguration(e, config.type)
                          }
                        />
                      </div> */}
                    </div>
                  ))}
                </form>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default ListCoordinator;
