import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/main.min.css";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/dashboard/Dashboard";

import ProtectiveRoute from "./components/ProtectiveRoute";
import ListDriver from "./pages/driver/ListDriver";
import ListNotification from "./pages/notification/ListNotification";
import ListCoordinator from "./pages/coordinator/ListCoordinator";
import ListUser from "./pages/user/ListUser";
import ListRides from "./pages/rides/ListRides";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer autoClose={3000} />
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/dashboard"
            element={
              <ProtectiveRoute redirectTo="/">
                <Dashboard />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/coordinator"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListCoordinator />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/driver"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListDriver />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/driver/:driverId"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListDriver />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListUser />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/user/:userId"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListUser />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/rides"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListRides />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/rides/:id"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListRides />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListNotification />
              </ProtectiveRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
