import axios from "axios";
import { baseUrl } from "../utils/env";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((req) => {
  const accessToken = localStorage.getItem("token");
  if (req.url !== "/") {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }
  return req;
});

instance.interceptors.response.use(null, async (error) => {
  const { status } = error.response;
  if ([404, 409, 400, 500].includes(status)) {
    throw error;
  } else if (status === 401) {
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      window.location.href = "/";
    }, 2000);
  }
  throw error;
});

export const doGet = (uri, params = {}) => instance.get(uri, params);
export const doPost = (uri, params = {}) => instance.post(uri, params);
export const doPut = (uri, params = {}) => instance.put(uri, params);
export const doDelete = (uri, params = {}) => instance.delete(uri, params);
