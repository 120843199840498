import { createSlice } from "@reduxjs/toolkit";
import { getRides } from "../thunk/ridesThunk";

const initialState = {
  rides: [],
  loading: false,
  success: false,
  error: null,
};

const ridesSlice = createSlice({
  name: "rides",
  initialState,
  reducers: {
    resetRides: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRides.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getRides.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.rides = action.payload;
        state.error = null;
      })
      .addCase(getRides.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetRides } = ridesSlice.actions;
export default ridesSlice.reducer;
