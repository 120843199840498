import React from "react";
import { logo } from "../../constant/ImageList";

const Loader = ({ isActive }) => {
  return (
    <div className={`loader ${isActive ? "active" : ""}`}>
      <div className="loader-element">
        <img src={logo} alt="Bali Visa Logo" />
      </div>
    </div>
  );
};

export default Loader;
