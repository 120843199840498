import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "./loader/Loader";
import { adminPassword } from "../redux/thunk/adminPasswordThunk";
import { useDispatch, useSelector } from "react-redux";

const TopHeader = ({ onSidebarToggle }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [adminPasswordModal, setAdminPasswordModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState(true);
  const [oldPasswordType, setOldPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { adminPasswordData } = useSelector(
    (state) => state.adminPasswordState
  );

  const handleLogout = async () => {
    setLoading(true);

    setLogoutModal(false);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      localStorage.removeItem("token");
      localStorage.removeItem("userDetails");
      toast.success("Logged out Successfully");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      isClicked && setIsClicked(false);
    }
  };

  useEffect(() => {
    if (adminPasswordData.result) {
      setAdminPasswordModal(false);
      setTimeout(() => {
        handleLogout();
      }, 4000);
    }
  }, [adminPasswordData.result, dispatch]);

  const handleAdminPassword = async (event) => {
    event.preventDefault();
    if (!oldPassword) {
      toast.error("Old Password is Required");
      return false;
    }
    if (!newPassword) {
      toast.error("New Password is Required");
      return false;
    }
    if (!confirmPassword) {
      toast.error("Confirm Password is Required");
      return false;
    }

    try {
      setLoading(true);
      const data = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      if (data) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(adminPassword(data));
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader isActive={true} />}
      <div onClick={onSidebarToggle} className="hamburger">
        <i className="fa-regular fa-bars"></i>
      </div>
      <div className="adminicons">
        <i
          className="fa-regular fa-key adminicons-password"
          onClick={() => setAdminPasswordModal(!adminPasswordModal)}
        ></i>
        <div
          className="adminicons-iconname"
          onClick={() => setIsClicked((prev) => !prev)}
          tabIndex={1}
          onBlur={handleBlur}
        >
          <i className="fa-regular fa-user"></i>
          <h2>{userDetails?.username}</h2>
          {isClicked && (
            <div
              className="logoutdiv"
              onClick={(e) => {
                setLogoutModal(!logoutModal);
                handleBlur(e);
              }}
            >
              <i className="fa-regular fa-right-from-bracket"></i>
              <span>Logout</span>
            </div>
          )}
          {logoutModal && (
            <div className="overlay">
              <div className="modal" style={{ width: "400px" }}>
                <div className="modal-header">
                  <h2>Logout</h2>
                  <i
                    onClick={() => setLogoutModal(false)}
                    className="fa-regular fa-times"
                  ></i>
                </div>

                <div className="modal-body">
                  <div className="approvediv">
                    <i
                      className="fa-regular fa-circle-exclamation"
                      style={{ background: "none" }}
                    ></i>
                    <p>Are you sure you want to logout?</p>
                    <div className="approvediv-btns">
                      <button
                        className="primary-button"
                        onClick={() => handleLogout()}
                      >
                        Yes
                      </button>
                      <button
                        className="secondary-button"
                        onClick={() => setLogoutModal(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {adminPasswordModal && (
        <div className="overlay">
          <div className="modal" style={{ width: "400px" }}>
            <div className="modal-header">
              <h2>Change Password</h2>
              <i
                onClick={() => setAdminPasswordModal(false)}
                className="fa-regular fa-times"
              ></i>
            </div>

            <div className="modal-body">
              <form action="" className="form" onSubmit={handleAdminPassword}>
                <div>
                  <label htmlFor="oldpassword">Old Password:</label>
                  <div className="passwordbutton">
                    <input
                      type={oldPasswordType ? "password" : "text"}
                      name="oldpassword"
                      id="oldpassword"
                      placeholder="Enter old password ..."
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOldPasswordType(!oldPasswordType);
                      }}
                      className="passwordbutton-button"
                    >
                      {oldPasswordType ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <label htmlFor="newpassword">New Password:</label>
                  <div className="passwordbutton">
                    <input
                      type={newPasswordType ? "password" : "text"}
                      name="newpassword"
                      id="newpassword"
                      placeholder="Enter new password ..."
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setNewPasswordType(!newPasswordType);
                      }}
                      className="passwordbutton-button"
                    >
                      {newPasswordType ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <label htmlFor="confirmpassword">Confirm Password:</label>
                  <div className="passwordbutton">
                    <input
                      type={confirmPasswordType ? "password" : "text"}
                      name="confirmpassword"
                      id="confirmpassword"
                      placeholder="Retype new password ..."
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmPasswordType(!confirmPasswordType);
                      }}
                      className="passwordbutton-button"
                    >
                      {confirmPasswordType ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="submitbtn">
                  <input
                    type="submit"
                    value="Change password"
                    className="primary-button"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopHeader;
