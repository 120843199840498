import { createSlice } from "@reduxjs/toolkit";
import { employeePassword } from "../thunk/employeePasswordThunk";

const initialState = {
  employeePasswordData: [],
  loading: false,
  success: false,
  error: null,
};

const employeePasswordSlice = createSlice({
  name: "employeePassword",
  initialState,
  reducers: {
    resetEmployeePassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(employeePassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(employeePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.employeePasswordData = action.payload;
        state.error = null;
      })
      .addCase(employeePassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetEmployeePassword } = employeePasswordSlice.actions;
export default employeePasswordSlice.reducer;
