import React from "react";
import { logo } from "../constant/ImageList";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  const navLinks = [
    { to: "/dashboard", text: "Dashboard", icon: "fa-regular fa-house" },
    {
      to: "/coordinator",
      text: "Coordinators",
      icon: "fa-regular fa-user-plus",
    },
    { to: "/driver", text: "Drivers", icon: "fa-regular fa-user-police" },
    { to: "/user", text: "Users", icon: "fa-regular fa-users" },
    { to: "/rides", text: "Rides", icon: "fa-regular fa-car" },
    {
      to: "/notification",
      text: "Notification",
      icon: "fa-regular fa-bell",
    },
  ];
  return (
    <>
      <div className="sidebarheader ">
        <div className="sidebarheader-sidebarheaderimage">
          <img src={logo} alt="" />
        </div>
        <h2>AUTOME</h2>
      </div>
      <div className="navigation">
        <div className="navigation">
          {navLinks.map((link, index) => (
            <NavLink
              key={index}
              className={`navigation-link ${
                location.pathname === link.to ? "active" : ""
              }`}
              to={link.to}
            >
              <i className={link.icon}></i> <span>{link.text}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
