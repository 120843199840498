import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet } from "../../axios/instance";
import { toast } from "react-toastify";

export const getRides = createAsyncThunk("getRides", async () => {
  try {
    const response = await doGet(`/system/rides?orderby=desc`);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(
        error.response?.data?.error || error.response.data.issues[0].message
      );
    }
    throw error;
  }
});
