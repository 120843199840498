import { createSlice } from "@reduxjs/toolkit";
import { adminPassword } from "../thunk/adminPasswordThunk";

const initialState = {
  adminPasswordData: [],
  loading: false,
  success: false,
  error: null,
};

const adminPasswordSlice = createSlice({
  name: "adminPassword",
  initialState,
  reducers: {
    resetAdminPassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(adminPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.adminPasswordData = action.payload;
        state.error = null;
      })
      .addCase(adminPassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetAdminPassword } = adminPasswordSlice.actions;
export default adminPasswordSlice.reducer;
